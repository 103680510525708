@import "settings";

body, #content {
  background:#FFEB3B;
}

.app-container {
  height:100%;
}

.divider {
    margin-top: 24px;
    margin-bottom: 16px;
}

.login-button {
  width:90%;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height:100%;
  width:100%;
}

.login-card {
  width: 430px;
  margin-left: auto;
  margin-right: auto;
  margin-top:auto;
  margin-bottom:auto;
}

.wider {
  width:400px;
  height:400px;
}

.between-or {
  line-height: 46px;
}

a {
  text-decoration: none;
}

#google-login-button {
  color: #4CAF50;
}

#facebook-login-button {
  color: #303F9F;
}

#twitter-login-button {
  color: #FFA000;
}

#create-dialog {
  min-width: 400px;
  md-input-container {
    width: 100%;
  }
}

.recipient-list {
  margin-bottom: 10px;
}

.add-email-input {
  margin-top:10px;
}

.my-drop-zone { border: dotted 3px lightgray; }
.nv-file-over { border: dotted 3px red; } /* Default class applied to drop zones on over */
.another-file-over-class { border: dotted 3px green; }

canvas {
    background-color: #f3f3f3;
    -webkit-box-shadow: 3px 3px 3px 0 #e3e3e3;
    -moz-box-shadow: 3px 3px 3px 0 #e3e3e3;
    box-shadow: 3px 3px 3px 0 #e3e3e3;
    border: 1px solid #c3c3c3;
    height: 100px;
    margin: 6px 0 0 6px;
}

.fix-rotate-overflow {
  overflow: hidden;
  max-width: 250px;
  margin-top: 18px;
}

.editable-input.editable-has-buttons {
  margin-top: 9px;
  height: 24px;
}

.album-option {
  min-width: 36px;
}